//Varibles for light and dark themes

export const LightTheme = {
  body: "#FCF6F4",
  text: "#000000",
  fontFamily: "'Source Sans Pro','sans-serif'",
  bodyRgba: "252, 246, 244",
  textRgba: "0,0,0",
};

export const DarkTheme = {
  body: "#000000",
  text: "#FCF6F4",
  fontFamily: "'Source Sans Pro','sans-serif'",
  bodyRgba: "252, 246, 244",
  textRgba: "0,0,0",
};
